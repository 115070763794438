import React from "react"
import PageWrapper from "../components/PageWrapper"

function Portfolio() {
  return (
    <PageWrapper>
      <h1>Portfolio</h1>
    </PageWrapper>
  )
}

export default Portfolio
